import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import snakeize from "snakecase-keys";

type LeaderboardParams = {
  type: string;
  page: number;
};

export const organizationQueries = {
  all: () => ["organization"] as const,
  lists: () => [...organizationQueries.all(), "list"] as const,
  list: () =>
    queryOptions({
      queryKey: [...organizationQueries.details(), "all"] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<ListOrganizationResponse>(
          `/organization/`
        );

        return camelize(response.data, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  details: () => [...organizationQueries.all(), "detail"] as const,
  detailByUrl: (url: MaybeRefOrGetter<string>) =>
    queryOptions({
      queryKey: [
        ...organizationQueries.details(),
        { url: toValue(url) },
      ] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<DetailResponse>(
          `/organization/getByUrl/${toValue(url)}`
        );

        return camelize(response.organization, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  detailById: (id: MaybeRefOrGetter<number>) =>
    [...organizationQueries.details(), { id: toValue(id) }] as const,
  summary: (id: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...organizationQueries.detailById(id), "summary"] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<SummaryResponse>(
          `/organization/${toValue(id)}/summary`
        );

        return camelize(response, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  updates: (id: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...organizationQueries.detailById(id), "updates"] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<UpdatesResponse>(
          `/organization/${toValue(id)}/updates`
        );

        return camelize(response, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  leaderboard: (
    id: MaybeRefOrGetter<number>,
    params: MaybeRefOrGetter<LeaderboardParams>
  ) =>
    queryOptions({
      queryKey: [
        ...organizationQueries.detailById(id),
        "leaderboard",
        { ...toValue(params) },
      ] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<LeaderboardResponse>(
          `/organization/${toValue(id)}/leaderboard`,
          { query: toValue(params) }
        );

        return camelize(response, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  giveaway: (id: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...organizationQueries.detailById(id), "giveaway"] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<GiveawayResponse>(
          `/organization/giveaway`,
          { query: snakeize({ organizationId: toValue(id) }) }
        );

        return camelize(response, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  tiers: (id: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...organizationQueries.detailById(id), "tiers"] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<TiersResponse>(`/organization/tiers`, {
          query: snakeize({ organizationId: toValue(id) }),
        });

        return camelize(response, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  badges: (id: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [...organizationQueries.detailById(id), "badges"] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<BadgesResponse>(
          `/organization/${toValue(id)}/badges`
        );

        return camelize(response, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
  recentMembers: (id: MaybeRefOrGetter<number>) =>
    queryOptions({
      queryKey: [
        ...organizationQueries.detailById(id),
        "recentMembers",
      ] as const,
      queryFn: async () => {
        const client = useApiClient();
        const response = await client<RecentMembersResponse>(
          `/organization/${toValue(id)}/recentMembers`
        );

        return camelize(response, { deep: true });
      },
      staleTime: 1000 * 60 * 60,
    }),
};

type ListOrganizationResponse = {
  data: Array<{
    id: number;
    name: string;
    by: string;
    created_at: string;
    brand_primary_color: string;
    default_url: {
      id: number;
      url: string;
      external: number;
      active: number;
      organization_id: number;
    };
    tags: [
      {
        id: number;
        name: string;
        created_at: string | null;
        updated_at: string | null;
        pivot: {
          organization_id: number;
          tag_id: number;
        };
      }
    ];
  }>;
};

type DetailResponse = {
  organization: {
    id: number;
    name: string;
    by: string;
    created_at: string;
    stripe_account_id: string | null;
    stripe_public_key: string;
    business_model: "tipping" | "percentage_fee";
    percentage_fee: number;
    accepts_ddc_campaigns: boolean;
    ddc_campaigns: number[];
    donors_type: "members" | "partners";
    brand_primary_color: string;
    showcase: boolean;
    default_url: {
      id: number;
      url: string;
      external: 0 | 1;
      active: 0 | 1;
    };
  };
};

type SummaryResponse = {
  membersCount: number;
  membersThisMonthCount: number;
  recurringDonationAmount: string;
  totalDonations: string;
};

type UpdatesResponse = Array<{
  id: number;
  organization_id: number;
  media: Array<{
    type: "image" | "vimeo" | "youtube";
    url: string;
  }>;
  title: string;
  content: string;
  created_at: string | null;
  updated_at: string | null;
  is_public: boolean;
  comments_count: number;
  likes: Array<{
    id: number;
    update_id: number;
    member_id: number | null;
    staff_id: number | null;
  }>;
}>;

type LeaderboardResponse = {
  total_pages: number;
  total_results: number;
  results: Array<{
    id: number;
    avatar_url: string | null;
    name: string;
    points: number;
    badges: Array<{
      id: number;
      badgeId: number;
      achievedAt: string;
      badge: {
        id: number;
        organizationId: number;
        title: string;
        description: string;
        icon: string;
        criteria:
          | "REFERRAL_NUMBER"
          | "ORDER_OF_SIGNUP"
          | "MONTHS_DONATED"
          | "LEADERBOARD_POSITION";
        value: number;
        perks: Array<{
          id: number;
          name: string;
          description: string | null;
        }>;
        createdAt: string;
        updatedAt: string;
      };
    }>;
  }>;
};

type GiveawayResponse = {
  giveaways: Array<{
    id: number;
    organization_id: number;
    title: string;
    poster_img: string;
    content: string;
    created_at: string | null;
    updated_at: string | null;
  }>;
};

type TiersResponse = Array<{
  id: number;
  name: string;
  description: string;
  icon: string;
  criteria: string;
  price: number;
  perks: Array<{
    id: number;
    tier_id: number;
    name: string;
    description: string | null;
    deliver_url: string;
    created_at: string;
    updated_at: string;
  }>;
}>;

type BadgesResponse = Array<{
  id: number;
  organization_id: number;
  title: string;
  description: string;
  icon: string;
  criteria: string;
  value: number;
  perks: Array<{
    id: number;
    badge_id: number;
    name: string;
    description: string | null;
    image: string | null;
  }>;
  created_at: string;
  updated_at: string;
}>;

type RecentMembersResponse = Array<{
  id: number;
  created_at: string;
  name: string;
  avatar_url: string;
  donation: number;
}>;
